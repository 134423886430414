import ApiError from "./ApiError.js"
import config from './config.js';
import moment from 'moment';
import sessionMgr from './sessionMgr.js';

export default class Credentials {

  constructor(tokenDetails) {
    this.accessToken = tokenDetails.access_token;
    this.refreshToken = tokenDetails.refresh_token;
    this.expiresAt = tokenDetails.expires_at;
  }

  static initFromString(credString) {
    const credObj = JSON.parse(credString);
    return new Credentials(credObj.clientId,
      credObj.clientSecret,
      credObj.apiKey);
  }
  testAuth = async () => {
    const url = `${config.accounts.url}/me`;
    const reqContent = {
      method: 'GET',
      headers: {
        "content-type": "application/json",
        "authorization": `Bearer ${await this.getToken()}`,
      }
    };
    const response = await fetch(url, reqContent);

    return response.ok;
  }

  refresh = async () => {
    const url = `${config.accounts.cognito_url}/oauth2/token`;
    const body = {
      client_id: config.accounts.oauth_params.client_id,
      refresh_token: this.refreshToken,
      grant_type: 'refresh_token'
    }
    const opts = {
      method: "POST",
      body: new URLSearchParams(body),
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }

    const res = await fetch(url, opts);
    const data = await res.json();

    if (res.ok) {
      return data;
    }

    throw new ApiError("Failed to refresh access token", res.status, data);
  }

  getToken = async () => {
    if (this.expiresAt <= Date.now() / 1000) {
      const data = await this.refresh();
      this.accessToken = data.access_token;
      this.expiresAt = moment().unix() + Number(data.expires_in);
    }

    return this.accessToken;
  }

}
