import sessionMgr from "./sessionMgr";
import config from "./config";
import _ from "lodash";

export function lfApiFetch(version, path, fetchOpts) {
  let tok = sessionMgr.getSession();
  let apiOpts = {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${tok.id_token}`
    }
  };

  return fetch(
    `${config.api_url}/${version}${path}`,
    _.merge(apiOpts, fetchOpts)
  ).then(response => response.json());
}


