import React from "react";
import { lfApiFetch } from "./util";
import _ from "lodash";
import moment from "moment";

import {
  Row,
  Col,
  Tab,
  Nav,
  Container,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import "./ReleaseNotes.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBug,
  faPlusCircle,
  faRecycle,
  faQuestion,
  faLaptopCode,
  faDatabase,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

function formatReleaseDate(date) {
  return moment(date, "YYYYMMDD").format("MMMM D, YYYY");
}

function ReleaseNoteOverviewTable(props) {
  let sortedNotes = _.reverse(
    _.sortBy(props.releaseNotes, (release) => release.release_number)
  );
  let rnRows = _.map(sortedNotes, (release) => {
    let clickHandler = (e) => {
      e.preventDefault();
      selectRelease(release.release_number);
    };
    return (
      <tr key={`overview-${release.release_number}`}>
        <td className="rn-release-number-col">
          <a href="#" onClick={clickHandler}>
            {release.api_version}
          </a>
        </td>
        <td className="rn-released-on-col">
          {formatReleaseDate(release.released_on)}
        </td>
        <td className="rn-summary-col">{release.summary}</td>
      </tr>
    );
  });

  return (
    <div className="overview-section">
      <Table bordered hover striped>
        <thead>
          <tr>
            <th className="rn-release-number-col">Release</th>
            <th className="rn-released-on-col">Date</th>
            <th className="rn-summary-col">Summary</th>
          </tr>
        </thead>
        <tbody>{rnRows}</tbody>
      </Table>
    </div>
  );
}

function FlagWithTooltip(props) {
  if (props.icon === null) {
    return null;
  }

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 100, hide: 100 }}
      overlay={<Tooltip>{props.children}</Tooltip>}
    >
      <FontAwesomeIcon icon={props.icon} />
    </OverlayTrigger>
  );
}

function ReleaseNoteUpdates(props) {
  let updateRows = [];
  props.updates.forEach((update, idx) => {
    let typeIcon = null;
    switch (update.update_type) {
      case "ENHANCEMENT":
        typeIcon = faPlusCircle;
        break;
      case "LIFECYCLE_OR_STABILITY_CHANGE":
        typeIcon = faRecycle;
        break;
      case "BUG_FIX":
        typeIcon = faBug;
        break;
      default:
        typeIcon = faQuestion;
    }
    let key = `${props.releaseNumber}-${props.ns}-${idx}`;
    console.log(key);
    updateRows.push(
      <tr key={key}>
        <td className="rn-update-type-flag-col">
          <FlagWithTooltip icon={typeIcon}>
            {update.update_type.replace("_", " ")}
          </FlagWithTooltip>
        </td>
        <td className="rn-update-msg-col">{update.update_msg}</td>
        <td className="rn-update-scope-flag-col">
          <FlagWithTooltip
            icon={update.update_scope.includes("API") ? faLaptopCode : null}
          >
            API
          </FlagWithTooltip>
        </td>
        <td className="rn-update-scope-flag-col">
          <FlagWithTooltip
            icon={update.update_scope.includes("DATA") ? faDatabase : null}
          >
            DATA
          </FlagWithTooltip>
        </td>
        <td className="rn-update-scope-flag-col">
          <FlagWithTooltip
            icon={update.update_scope.includes("DOCS") ? faBook : null}
          >
            DOCS
          </FlagWithTooltip>
        </td>
      </tr>
    );
  });
  return (
    <Table className="rn-summary" bordered hover striped>
      <thead>
        <tr>
          <th className="rn-update-type-col">Type</th>
          <th className="rn-update-msg-col">Update Message</th>
          <th className="rn-update-scope-col" colSpan="3">
            Update Scope
          </th>
        </tr>
      </thead>
      {updateRows}
    </Table>
  );
}
function ReleaseNoteDetails(props) {
  let groupedChanges = _.groupBy(
    props.release.updates,
    (update) => update.breaking_change === true
  );
  let breakingUpdates = groupedChanges[true];
  let otherUpdates = groupedChanges[false];
  let breakingRow = null;
  if (breakingUpdates && breakingUpdates.length > 0) {
    breakingRow = (
      <Row>
        <Col>
          <h2>Breaking Updates</h2>
          <ReleaseNoteUpdates
            ns="breaking"
            releaseNumber={props.release.api_version}
            updates={breakingUpdates}
          />
        </Col>
      </Row>
    );
  }

  let otherRow = null;
  if (otherUpdates && otherUpdates.length > 0) {
    otherRow = (
      <Row>
        <Col>
          <h2>Backwards Compatible Updates</h2>
          <ReleaseNoteUpdates
            ns="compatible"
            releaseNumber={props.release.release_number}
            updates={otherUpdates}
          />
        </Col>
      </Row>
    );
  }

  return (
    <div className="ReleaseNotes-details">
      <Row>
        <Col>
          <h1>
            {formatReleaseDate(props.release.released_on)} -{" "}
            {props.release.api_version}
          </h1>
          <p>
            {props.release.summary || "TODO: draft a summary for this release"}
          </p>
        </Col>
      </Row>
      {breakingRow}
      {otherRow}
    </div>
  );
}

class ReleaseNotesOverview extends React.Component {
  render() {
    return (
      <div className="ReleaseNotes-details">
        <Row>
          <Col>
            <h1>Overview</h1>
            <p>
              Release notes document versioned changes to the ListenFirst API.
              All releases notes detail the scope of updates, including:
            </p>
            <ul>
              <li>the date of the release</li>
              <li>a summary outlining the key changes</li>
              <li>
                details of all enhancements, bug fixes, and data dictionary
                updates
              </li>
            </ul>
            <p>
              To learn more about our release process and policies please see
              our&nbsp;
              <a href="/docs#section/Version-Management">
                Version Management
              </a>{" "}
              documentation. Release notes are also available via the
              API's&nbsp;
              <a href="/docs#tag/platform/paths/~1v20200626~1platform~1release_notes/get">
                /platform/release_notes
              </a>{" "}
              endpoint as well.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReleaseNoteOverviewTable releaseNotes={this.props.releaseNotes} />
          </Col>
        </Row>
      </div>
    );
  }
}

function selectRelease(key) {
  let links = document.querySelectorAll(`[data-rb-event-key="${key}"]`);
  _.map(links, (links) => links.click());
}

class ReleaseNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      releaseNotes: null,
    };

    this.loadRelease = this.loadRelease.bind(this);
  }

  setLoading(val) {
    this.setState({ isLoading: val });
  }

  setReleaseNotes(releaseNotes) {
    this.setState({ releaseNotes });
  }

  componentDidMount() {
    this.setLoading(true);
    lfApiFetch(this.props.version, "/platform/release_notes")
      .then((releaseNotes) => {
        console.log(`loaded all release notes`);
        console.log(releaseNotes.records);
        let sortedNotes = _.reverse(
          _.sortBy(releaseNotes.records, (release) => release.release_number)
        );
        this.setReleaseNotes(sortedNotes);
        this.setLoading(false);

        if (window.location.hash && window.location.hash.length > 1) {
          let releaseKey = window.location.hash.substr(1);
          selectRelease(releaseKey);
        }
      })
      .catch((error) => {
        console.log("Failed to fetch release notes");
        console.log(error);
      });
  }

  loadRelease(key, event) {
    if (event) {
      event.preventDefault();
    }

    if (key === "overview") {
      window.location = "#";
      return;
    }

    window.location = `#${key}`;
  }

  render() {
    let navLinks = _.map(this.state.releaseNotes, (release) => {
      return (
        <Nav.Item key={release.release_number}>
          <Nav.Link
            eventKey={release.release_number}
            onClick={(e) => this.loadRelease(release.release_number)}
            onFocus={(e) => this.loadRelease(release.release_number)}
          >
            {formatReleaseDate(release.released_on)} - {release.api_version}
          </Nav.Link>
        </Nav.Item>
      );
    });

    let overviewHandlerFn = (e) => this.loadRelease("overview", e);
    let overviewNav = (
      <Nav.Item key="overview">
        <Nav.Link
          eventKey="overview"
          onClick={overviewHandlerFn}
          onFocus={overviewHandlerFn}
        >
          Overview
        </Nav.Link>
      </Nav.Item>
    );
    navLinks.unshift(overviewNav);

    let tabPanes = _.map(this.state.releaseNotes, (release) => {
      return (
        <Tab.Pane
          eventKey={release.release_number}
          key={release.release_number}
        >
          <ReleaseNoteDetails release={release} />
        </Tab.Pane>
      );
    });

    let overviewTabPane = (
      <Tab.Pane eventKey="overview" key="overview">
        <ReleaseNotesOverview releaseNotes={this.state.releaseNotes} />
      </Tab.Pane>
    );
    tabPanes.unshift(overviewTabPane);

    let progressBar = null;
    if (this.state.isLoading) {
      progressBar = (
        <Row className="loading-row justify-content-sm-center">
          <Col sm="8">
            <Spinner animation="border" variant="warning" size="sm" />
          </Col>
        </Row>
      );
    }

    let dictTabContainer = (
      <Tab.Container
        className="ReleaseNotes-Container"
        defaultActiveKey="overview"
      >
        <Row>
          <Col sm="2" id="ReleaseNotes-Sidebar">
            <Nav variant="pills" className="flex-column">
              {navLinks}
            </Nav>
          </Col>
          <Col sm="10" md="10" lg="10" xl="7" id="ReleaseNotes-MainContent">
            <Tab.Content>{tabPanes}</Tab.Content>
          </Col>
          <Col xl="3" className="d-none d-xl-block" id="ReleaseNotes-RightRail">
            &nbsp;
          </Col>
        </Row>
      </Tab.Container>
    );

    let contents = this.state.isLoading ? progressBar : dictTabContainer;

    return (
      <Container className="ReleaseNotes-Container" fluid>
        {contents}
      </Container>
    );
  }
}

export default ReleaseNotes;
