import React from 'react';
import {lfApiFetch} from './util';
import _ from 'lodash';

import {
  Row,
  Col,
  Tab,
  Nav,
  Container,
  Table,
  Spinner,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';

import './DataDictionary.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faChartLine, faFilter, faSortAmountDown, faObjectGroup, faList, faMinus, faCircle, faKey, faEye, faChevronRight } from '@fortawesome/free-solid-svg-icons';


function FlagWithTooltip(props) {
  if (props.icon === null) {
    return null;
  }

  return (
    <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={
      <Tooltip>{props.children}</Tooltip>
    }>
      <FontAwesomeIcon icon={props.icon} />
    </OverlayTrigger>
  )
}

function FieldRow(props) {
  let f = props.field;
  let publicIcon = f.public ? faEye : faKey;
  let intervalIcon = f.interval === "DELTA" ? faChartBar : faChartLine;
  if (props.field.class === "DIMENSION") {
    intervalIcon = faMinus;
  }

  let filterIcon = null;
  if (_.includes(f.capabilities, "FILTERABLE")) {
    filterIcon = faFilter;
  }

  let sortIcon = null;
  if (_.includes(f.capabilities, "SORTABLE")) {
    sortIcon = faSortAmountDown;
  }

  let groupIcon = null;
  if (_.includes(f.capabilities, "GROUPABLE")) {
    groupIcon = faObjectGroup;
  }

  let listIcon = null;
  if (f.listable === true) {
    listIcon = faList;
  }

  return (
    <tr key={f.id}>
      {/* <td>{f.id}</td> */}
      <td className="field-name-col">{f.name} <br /> <small>{f.id}</small></td>
      <td className="field-data-type-col">{f.data_type}</td>
      <td className="field-description-col">{f.description}</td>
      <td className="field-flag-col">
        <FlagWithTooltip icon={publicIcon}>
          {f.public ? 'PUBLIC' : 'AUTHORIZED'}
        </FlagWithTooltip>
      </td>
      <td className="field-flag-col">
        <FlagWithTooltip icon={intervalIcon}>
          INTERVAL: {f.interval ? f.interval : "N/A"}
        </FlagWithTooltip>
      </td>
      <td className="field-flag-col">
        <FlagWithTooltip icon={filterIcon}>
          FILTERABLE
        </FlagWithTooltip>
      </td>
      <td className="field-flag-col">
        <FlagWithTooltip icon={sortIcon}>
          SORTABLE
        </FlagWithTooltip>
      </td>
      <td className="field-flag-col">
        <FlagWithTooltip icon={groupIcon}>
          GROUPABLE
        </FlagWithTooltip>
      </td>
      <td className="field-flag-col">
        <FlagWithTooltip icon={listIcon}>
          LISTABLE
        </FlagWithTooltip>
      </td>
      <td className="field-flag-col">
        <OverlayTrigger placement="top" delay={{ show: 100, hide: 100 }} overlay={
          <Tooltip>STABILITY STATUS: {f.stability_state}</Tooltip>
        }>
          <FontAwesomeIcon icon={faCircle} className={`stability-${f.stability_state.toLowerCase()}`} />
        </OverlayTrigger>
      </td>
    </tr>
  )
}

function FieldHeaderRow(props) {
  return (
    <tr>
      <th className="field-name-col">Name</th>
      <th className="field-data-type-col">Data Type</th>
      <th className="field-description-col">Description</th>
      <th className="field-attributes-col" colSpan="6">Field Attributes</th>
      <th className="field-stability-col">&nbsp;</th>
    </tr>
  );
}

function FieldsCard(props) {
  if (props.fields === undefined || props.fields.length === 0) {
    return null;
  }

  let rows = _.map(props.fields, (field) => ( <FieldRow key={field.id} field={field} /> ));
  return (
    <Row>
      <Col>
        <h2>{props.title}</h2>
        <Table striped responsive bordered size="sm">
          <thead>
            <FieldHeaderRow />
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

function DatasetDetails(props) {
  let dimsAndMetrics = _.partition(props.dataset.fields, (field) => field.class === "DIMENSION");
  let dims = _.sortBy(dimsAndMetrics[0], (dim) => dim.name);
  let metrics = _.sortBy(dimsAndMetrics[1], (metric) => metric.name)
  return (
    <div className="dsDetails">
      <Row>
        <Col>
          <h1>{props.dataset.name}</h1>
          <p>{props.dataset.description}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className="ds-summary" responsive borderless size="sm">
            <tbody>
              <tr>
                <td className="dsAttrName">Dataset ID</td>
                <td className="dsAttrVal">{props.dataset.id}</td>
              </tr>
              <tr>
                <td className="dsAttrName">Dataset Type</td>
                <td className="dsAttrVal">{props.dataset.dataset_type}</td>
              </tr>
              <tr>
                <td className="dsAttrName">Analysis Type</td>
                <td className="dsAttrVal">{props.dataset.analysis_type}</td>
              </tr>
              <tr>
                <td className="dsAttrName">Primary Time Field</td>
                <td className="dsAttrVal">{props.dataset.primary_time_field}</td>
              </tr>
              <tr>
                <td className="dsAttrName">Stat Attribution Modes</td>
                <td className="dsAttrVal">
                  {_.join(props.dataset.stat_attribution_modes.sort(), ", ")}
                </td>
              </tr>
              <tr>
                <td className="dsAttrName">Stability State</td>
                <td className="dsAttrVal">{props.dataset.stability_state}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldsCard title="Dimensions" fields={dims} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldsCard title="Metrics" fields={metrics} />
        </Col>
      </Row>
    </div>
  );
}

function titlecase(str) {
  return str; //_.join(_.map(_.split(str, ' '), (word) => _.capitalize(word)), ' ');
}

function cleanShortName(name) {
  return titlecase(_.trim(name.replace(/^dataset/i, '')));
}

function selectDataset(key) {
  let links = document.querySelectorAll(`[data-rb-event-key="${key}"]`);
  _.map(links, (links) => links.click());
}
function OverviewTableRow(props) {
  let clickHandler = (e) => {
    e.preventDefault();
    selectDataset(props.dataset.id);
  };

  return (
    <tr>
      <td>
        <a href="#" onClick={clickHandler}>{props.dataset.name}</a>
      </td>
      <td>{props.dataset.description}</td>
    </tr>
  );
}

function OverviewTable(props) {
  if (props.datasets === undefined || props.datasets === null || props.datasets.length === 0) {
    return null;
  }

  let sortedSets = _.sortBy(props.datasets, (ds) => ds.name);
  let dsRows = _.map(sortedSets, (dataset) => (<OverviewTableRow dataset={dataset} key={dataset.id}/>));
  return (
    <div className="overview-section">
      <h3>{props.title}</h3>
      <p>{props.children}</p>
      <Table bordered hover striped>
        <thead>
          <tr>
            <th>Dataset</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {dsRows}
        </tbody>
      </Table>
    </div>
  );
}

function groupDatasets(datasets) {
  let metaSets = _.filter(datasets, (dataset) => (dataset.dataset_type === 'DIMENSION_GROUP'));
  let utilitySets = _.filter(datasets, (dataset) => (dataset.dataset_type === 'UTILITY'));

  const analysisSets = _.filter(datasets, (dataset) => (dataset.dataset_type === 'ANALYTIC'));
  const analysisSetsToTypesMap = {
    brandAnalysisSets: 'BRAND',
    contentAnalysisSets: 'CONTENT',
    paidAnalysisSets: 'PAID',
    profileAnalysisSets: 'PROFILE'
  };
  const analysisTypes = _.mapValues(analysisSetsToTypesMap, (type) =>
    _.filter(analysisSets, (dataset) => dataset.analysis_type === type)
  );

  return {
    ...analysisTypes,
    metaSets,
    utilitySets
  };
}
class DictOverview extends React.Component {

  render() {
    let dsg = groupDatasets(this.props.datasets);
    const analyticDatasetsConfig = {
      brandAnalysisSets: {
        title: 'Brand Analysis',
        body: "Brand Analysis Datasets provide dimensions and metrics for performing multi-dimensional analytical queries against views containing data rolled up to each Brand daily.  All Brands' Data Sources are rolled up daily."
      },
      contentAnalysisSets: {
        title: 'Content Analysis',
        body: "Content Analysis Datasets provide dimensions and metrics for performing multi-dimensional analytical queries against views containing data rolled up to each Post daily.  All Posts' facts are rolled up daily."
      },
      paidAnalysisSets: {
        title: 'Paid Analysis',
        body: "Paid Analysis Datasets provide dimensions and metrics for performing multi-dimensional analytical queries against views containing data rolled up to each Ad daily.  All Ads' facts are rolled up daily."
      },
      profileAnalysisSets: {
        title: 'Profile Analysis',
        body: "Profile Analysis datasets provide dimensions and metrics for performing multi-dimensional analytical queries against views containing data rolled up to each Profile daily. All Profiles' facts are rolled up daily."
      }
    };
    return (
      <div className="dsDetails">
        <Row>
          <Col>
            <h1>Overview</h1>
            <p>
              The Data Dictionary is a queryable metadata repository containing the definitions, characteristics,
              constraints, and other important attributes of Datasets, Dimensions, and Metrics available from
              the ListenFirst Platform.  The contents of the Data Dictionary are available here for reference.
              All data shown in this section is available from&nbsp;
              <a href="/docs#tag/analytics/paths/~1v20200626~1analytics~1datasets/get">the API's dataset endpoints</a>.
            </p>

            <p>
              <a href='/docs#section/The-Analytics-API/Analytics-Data-Model'>The Analytics API Data Model</a> provides
              definitions and explanations of all the objects and attributes found in the Data Dictionary.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Analytic Datasets</h2>
            <p>
              These datasets are supported for use with the&nbsp;
              <a href="/docs#tag/analytics/paths/~1v20200626~1analytics~1fetch/post">/analytics/fetch</a> endpoint.
            </p>
          </Col>
        </Row>
        {_.map(analyticDatasetsConfig, (config, datasetKey) => (
          <Row key={datasetKey}>
            <Col>
              <OverviewTable datasets={dsg[datasetKey]} title={config.title}>
                {config.body}
              </OverviewTable>
            </Col>
          </Row>
        ))}
        <Row>
          <Col>
            <h2>Dimension Group Datasets</h2>
            <p>
              These datasets are supported for use with metadata endpoints, such as the&nbsp;
              <a href="/docs#tag/brand_views/paths/~1v20200626~1brand_views/get">/brand_views</a>&nbsp;
              endpoint and the <a href="/docs#tag/analytics/fetch/paths/~1v20200626~1analytics~1fetch/post">/analytics/fetch</a>&nbsp;
              endpoint for the ListenFirst Content Dimension Group.
            </p>
            <OverviewTable datasets={dsg.metaSets} title="" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Utility Datasets</h2>
            <p>
              These are secondary datasets that can be used for maintenance processes&nbsp; 
	      such as tracking deleted posts. They can be retrieved from the <a href="/docs#tag/analytics/fetch/paths/~1v20200626~1analytics~1fetch/post">/analytics/fetch</a> endpoint.
            </p>
            <OverviewTable datasets={dsg.utilitySets} title="" />
          </Col>
        </Row>
      </div>
    );
  }
}

class DataDictionaryNavGroup extends React.Component {
  constructor (props) {
    super(props);
    this.state = {selected: false};
    this.toggleGroup = this.toggleGroup.bind(this);
    this.showGroup = this.showGroup.bind(this);
    this.hideGroup = this.hideGroup.bind(this);
  }

  componentDidMount() {
    this.hideGroup();
  }

  toggleGroup(e) {
    console.log(`Toggling ${this.props.group}`);
    if (e) {
      e.preventDefault();
    }

    let groupElm = document.getElementById(`nav-${this.props.group}`);
    if (groupElm.style.display === 'none') {
      this.showGroup();
    } else {
      this.hideGroup();
    }
  }

  showGroup() {
    let groupElm = document.getElementById(`nav-${this.props.group}`);
    groupElm.style.display = 'block';
    this.setState({selected: true});

    _.map(document.getElementsByClassName("section-cntrl"), (elm) => {
      if (elm.classList.contains('active') && elm.id !== this.props.group) {
        elm.click();
      }
    });
    document.getElementById(this.props.group).classList.add('active');
  }

  hideGroup() {
    let groupElm = document.getElementById(`nav-${this.props.group}`);
    groupElm.style.display = 'none';
    this.setState({selected: false});
    document.getElementById(this.props.group).classList.remove('active');
  }

  render() {
    let navItems = _.map(this.props.datasets, (dataset) => {
      let handlerFn = (e) => {
        this.showGroup();
        this.props.onDataset(dataset.id, e);
      }

      return (
        <Nav.Item key={dataset.id}>
          <Nav.Link eventKey={dataset.id} onClick={handlerFn} onFocus={handlerFn}>
            {cleanShortName(dataset.name)}
          </Nav.Link>
        </Nav.Item>
      );
    });

    let groupName = null;
    switch (this.props.group) {
      case "metaSets":
        groupName = "Dimension Group Datasets"
        break;
      case "utilitySets":
        groupName = "Utility Datasets"
        break;
      case "brandAnalysisSets":
        groupName = "Brand Analysis Datasets"
        break;
      case "contentAnalysisSets":
        groupName = "Content Analysis Datasets"
        break;
      case "paidAnalysisSets":
        groupName = "Paid Analysis Datasets"
        break;
      case "profileAnalysisSets":
        groupName = "Profile Analysis Datasets"
        break;
      default:
        groupName = this.props.group;
    }

    return (
      <div className='nav-group'>
        <button type="button" className="section-cntrl" id={this.props.group} onClick={this.toggleGroup}>
          {groupName}
          <FontAwesomeIcon icon={faChevronRight} className="section-cntrl-icon"/>
        </button>
        <Nav variant="pills" className="flex-column section-nav-items" id={`nav-${this.props.group}`}>
          {navItems}
        </Nav>
      </div>
    )
  }
}

class DataDictionary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datasets: null,
      selectedDataset: null
    }

    this.loadDataset = this.loadDataset.bind(this);
  }

  setLoading(val) {
    this.setState({isLoading: val});
  }

  setDatasets(datasets) {
    this.setState({datasets});
  }

  componentDidMount() {
    this.setLoading(true);
    lfApiFetch(this.props.version, '/dictionary/datasets')
      .then((datasets) => {
        console.log(`loaded all ${datasets.record_count} datasets`);
        let sortedSets = _.sortBy(datasets.records, (ds) => ds.name);
        this.setDatasets(sortedSets);
        this.setLoading(false);

        if (window.location.hash && window.location.hash.length > 1) {
          let datasetKey = window.location.hash.substr(1);
          selectDataset(datasetKey);
        }
      })
      .catch((error) => {
        console.log("Failed to fetch spec");
        console.log(error);
      });
  }

  loadDataset(key, event) {
    if (event) {
      event.preventDefault();
    }

    if (key === 'overview') {
      window.location = '#';
      return;
    }

    window.location = `#${key}`;


    let cachedFetch = new Promise((resolve, reject) => {
      if (this.state.dict && this.state.dict[key]) {
        resolve({record: this.state.dict[key]});
      } else {
        resolve(lfApiFetch(this.props.version, `/dictionary/datasets/${key}`));
      }
    });

    cachedFetch
      .then((res) => {
        let dataset = res.record;
        this.setState((s,p) => {
          let dict = s.dict || {};
          dict[dataset.id] = dataset;
          return {dict};
        });
      })
      .catch((error) => {
        console.log(`Failed to fetch dataset details: ${key}`);
        console.log(error);
      });
  }


  render() {

    let dsg = groupDatasets(this.state.datasets);
    let dsgKeys = _.keys(dsg);

    let navGroups = _.map(dsgKeys, (group)=> {
      let datasets = dsg[group];
      return (
        <DataDictionaryNavGroup group={group} datasets={datasets} onDataset={this.loadDataset}/>
      )
    })

    let overviewHandlerFn = (e) => this.loadDataset('overview', e);
    let overviewNav = (
      <Nav.Item key="overview">
        <Nav.Link eventKey="overview" onClick={overviewHandlerFn} onFocus={overviewHandlerFn}>Overview</Nav.Link>
      </Nav.Item>
    );
    navGroups.unshift(overviewNav);

    let tabPanes = _.map(this.state.datasets, (dataset)=>{
      let datasetDetails = this.state.dict && this.state.dict[dataset.id];
      let contents = null;
      if (datasetDetails === undefined || datasetDetails === null) {
        contents = (
          <Spinner animation="border" variant="warning" size="sm"/>
        );
      } else {
        contents = <DatasetDetails dataset={datasetDetails} />
      }

      return (
        <Tab.Pane eventKey={dataset.id} key={dataset.id}>
          {contents}
        </Tab.Pane>
      )
    });

    let overviewTabPane = (
      <Tab.Pane eventKey="overview" key="overview">
        <DictOverview datasets={this.state.datasets} />
      </Tab.Pane>
    );
    tabPanes.unshift(overviewTabPane);

    let progressBar = null;
    if (this.state.isLoading) {
      progressBar = (
        <Row className="loading-row justify-content-sm-center">
          <Col sm="8">
            <Spinner animation="border" variant="warning" size="sm" />
          </Col>
        </Row>
      );
    }

    let dictTabContainer = (
      <Tab.Container className="DataDictionary-Container" defaultActiveKey="overview">
        <Row>
          <Col sm="2" id="DataDictionary-Sidebar" >
            <Nav variant="pills" className="flex-column">
              {navGroups}
            </Nav>
          </Col>
          <Col sm="10" md="10" lg="10" xl="7" id="DataDictionary-MainContent">
            <Tab.Content>
              {tabPanes}
            </Tab.Content>
          </Col>
          <Col xl="3" className="d-none d-xl-block" id="DataDictionary-RightRail">
            &nbsp;
          </Col>
        </Row>
      </Tab.Container>
    );

    let contents = (this.state.isLoading) ? progressBar : dictTabContainer;

    return (
      <Container className="DataDictionary-Container" fluid>
        {contents}
      </Container>
    );
  }
}

export default DataDictionary;
