import React from 'react';
import {Component} from 'react';
import { RedocStandalone } from 'redoc';
import {lfApiFetch} from './util';
import './ApiDocs.css';

import {
  Row,
  Col,
  Spinner,
} from 'react-bootstrap';


const apiOptions = {
  //disableSearch - disable search indexing and search box.
  //disableSearch: true,
  //expandDefaultServerVariables - enable expanding default server variables, default false.
  //expandResponses - specify which responses to expand by default by response codes. Values should be passed as comma-separated list without spaces e.g. expandResponses="200,201". Special value "all" expands all responses by default. Be careful: this option can slow-down documentation rendering time.
  expandResponses: "200",
  //hideDownloadButton - do not show "Download" spec button. THIS DOESN'T MAKE YOUR SPEC PRIVATE, it just hides the button.
  //hideDownloadButton: true,
  //hideHostname - if set, the protocol and hostname is not shown in the operation definition.
  //hideLoading - do not show loading animation. Useful for small docs.
  //hideSingleRequestSampleTab - do not show the request sample tab for requests with only one sample.
  //expandSingleSchemaField - automatically expand single field in a schema
  //jsonSampleExpandLevel - set the default expand level for JSON payload samples (responses and request body). Special value 'all' expands all levels. The default value is 2.
  //lazyRendering - Not implemented yet if set, enables lazy rendering mode in ReDoc. This mode is useful for APIs with big number of operations (e.g. > 50). In this mode ReDoc shows initial screen ASAP and then renders the rest operations asynchronously while showing progress bar on the top. Check out the demo for the example.
  //menuToggle - if true clicking second time on expanded menu item will collapse it, default false.
  menuToggle: true,
  //nativeScrollbars - use native scrollbar for sidemenu instead of perfect-scroll (scrolling performance optimization for big specs).
  //nativeScrollbars: true,
  //noAutoAuth - do not inject Authentication section automatically.
  //noAutoAuth: true,
  //onlyRequiredInSamples - shows only required fields in request samples.
  //pathInMiddlePanel - show path link and HTTP verb in the middle panel instead of the right one.
  //requiredPropsFirst - show required properties first ordered in the same order as in required array.
  //scrollYOffset - If set, specifies a vertical scroll-offset. This is often useful when there are fixed positioned elements at the top of the page, such as navbars, headers etc; scrollYOffset can be specified in various ways:
  scrollYOffset: '.Header-Navbar',
  //showExtensions - show vendor extensions ("x-" fields). Extensions used by ReDoc are ignored. Can be boolean or an array of string with names of extensions to display.
  //sortPropsAlphabetically - sort properties alphabetically.
  //suppressWarnings - if set, warnings are not rendered at the top of documentation (they still are logged to the console).
  //payloadSampleIdx - if set, payload sample will be inserted at this index or last. Indexes start from 0.
  //untrustedSpec - if set, the spec is considered untrusted and all HTML/markdown is sanitized to prevent XSS. Disabled by default for performance reasons. Enable this option if you work with untrusted user data!
  //theme - ReDoc theme. Not documented yet. For details check source code: theme.ts.
  theme: {
    spacing: {
      // unit: 5,
      sectionHorizontal: ({ spacing }) => spacing.unit * 8,
      sectionVertical: ({ spacing }) => spacing.unit * 2,
    },
    breakpoints: {
      // small: '50rem',
      // medium: '85rem',
      // large: '105rem',
    },
    colors: {
      // tonalOffset: 0.3,
      primary: {
        //dark: '#bba21b',
        main: '#E4C516',
        // light: ({ colors }) => lighten(colors.tonalOffset, colors.primary.main),
        // dark: ({ colors }) => darken(colors.tonalOffset, colors.primary.main),
        // contrastText: ({ colors }) => readableColor(colors.primary.main),
      },
      success: {
//        main: '#007070',
        main: '#16B749',
        // light: ({ colors }) => lighten(colors.tonalOffset * 2, colors.success.main),
        // dark: ({ colors }) => darken(colors.tonalOffset, colors.success.main),
        // contrastText: ({ colors }) => readableColor(colors.success.main),
      },
      warning: {
        // main: '#ffa500',
        // light: ({ colors }) => lighten(colors.tonalOffset, colors.warning.main),
        // dark: ({ colors }) => darken(colors.tonalOffset, colors.warning.main),
        // contrastText: '#ffffff',
      },
      error: {
        main: '#B5341F',
        // light: ({ colors }) => lighten(colors.tonalOffset, colors.error.main),
        // dark: ({ colors }) => darken(colors.tonalOffset, colors.error.main),
        // contrastText: ({ colors }) => readableColor(colors.error.main),
      },
      gray: {
        50: '#D7D7D7',
        100: '#6d6e70',
      },
      text: {
        primary: '#1e1e1e',
        // secondary: ({ colors }) => lighten(colors.tonalOffset, colors.text.primary),
      },
      border: {
        // dark: 'rgba(0,0,0, 0.1)',
        // light: '#ffffff',
      },
      responses: {
        success: {
          // color: ({ colors }) => colors.success.main,
          backgroundColor: '#A1F09E'
          // backgroundColor: ({ colors }) => transparentize(0.9, colors.success.main),
        },
        error: {
          // color: ({ colors }) => colors.error.main,
          // backgroundColor: ({ colors }) => transparentize(0.9, colors.error.main),
        },
        redirect: {
          // color: ({ colors }) => colors.warning.main,
          // backgroundColor: ({ colors }) => transparentize(0.9, colors.responses.redirect.color),
        },
        info: {
          // color: '#87ceeb',
          // backgroundColor: ({ colors }) => transparentize(0.9, colors.responses.info.color),
        },
      },
      http: {
        //get: '#6bbd5b',
        get: '#01E676',
        //get: '#16B749',
        //post: '#248fb2',
        post: '#03B0FF',
        // put: '#9b708b',
        // options: '#d3ca12',
        // patch: '#e09d43',
        // delete: '#e27a7a',
        // basic: '#999',
        // link: '#31bbb6',
        // head: '#c167e4',
      },
    },
    schema: {
      // linesColor: theme =>
      //   lighten(
      //     theme.colors.tonalOffset,
      //     desaturate(theme.colors.tonalOffset, theme.colors.primary.main),
      //   ),
      //defaultDetailsWidth: '75%',
      //typeNameColor: theme => theme.colors.text.secondary,
      //typeTitleColor: theme => theme.schema.typeNameColor,
      //requireLabelColor: theme => theme.colors.error.main,
      labelsTextSize: '0.9em',
      //nestingSpacing: '1em',
      //nestedBackground: '#fafafa',
      // arrow: {
      //   size: '1.1em',
      //   color: theme => theme.colors.text.secondary,
      // },
    },
    typography: {
      fontSize: '14px',
      lineHeight: '1.5em',
      fontWeightRegular: '400',
      fontWeightBold: '600',
      fontWeightLight: '300',
      //fontFamily: '"Gotham Rounded A","Gotham Rounded B",Helvetica, Arial, sans-serif',
      smoothing: 'antialiased',
      optimizeSpeed: true,
      headings: {
        //fontFamily: '"Gotham Rounded A","Gotham Rounded B",Helvetica,Arial,sans-serif',
        fontWeight: '400',
        lineHeight: '1.6em'
      },
      code: {
        fontSize: '13px',
        fontFamily: 'Courier, monospace',
        lineHeight: ({ typography }) => typography.lineHeight,
        fontWeight: ({ typography }) => typography.fontWeightRegular,
        color: '#e53935',
        backgroundColor: 'rgba(38, 50, 56, 0.05)',
        wrap: false,
      },
      links: {
        //color: ({ colors }) => colors.primary.main,
        color: '#0081ED',
        visited: ({ typography }) => typography.links.color,
        // hover: ({ typography }) => lighten(0.2, typography.links.color),
      },
    },
    sidebar: {
      width: '260px',
      backgroundColor: '#D5D5D5',
      textColor: '#1e1e1e',
      activeTextColor: theme =>
        theme.sidebar.textColor !== '#1e1e1e' // defaultTheme.sidebar!.textColor
        ? theme.sidebar.textColor
        : '#0081ED',  //theme.colors.primary.main,
      groupItems: {
        textTransform: 'uppercase',
      },
      level1Items: {
        textTransform: 'uppercase',
      },
      arrow: {
        size: '1.5em',
        color: theme => theme.sidebar.textColor,
      },
    },
    logo: {
      maxHeight: 0,//({ sidebar: menu }) => menu.width,
      maxWidth: 0, //({ sidebar: menu }) => menu.width,
      gutter: '2px',
    },
    rightPanel: {
      backgroundColor: '#6D6E70',
      width: '35%',
      textColor: '#fafafa',
    },
    codeBlock: {
      backgroundColor: '#404041'
      // backgroundColor: ({ rightPanel }) => darken(0.1, rightPanel.backgroundColor),
    },
  }
};

class ApiDocs extends Component {
  constructor(props) {
    super(props);
    this.state = { spec: null };
  }

  componentDidMount() {
    lfApiFetch(this.props.version, '/spec')
      .then((data) => {
        console.log("Loaded spec form url!");
        this.setState({spec: data});
      })
      .catch((error) => {
        console.log("Failed to fetch spec");
        console.log(error);
      });
  }

  render() {
    if (this.state.spec) {
      return (
        <RedocStandalone spec={ this.state.spec } options={apiOptions} />
      );
    } else {
      return (
         <Row className="loading-row justify-content-sm-center">
          <Col sm="8">
            <Spinner animation="border" variant="warning" size="sm" />
          </Col>
         </Row>
      );
    }
  }
}

export default ApiDocs;
