import React from "react";
import _ from "lodash";

import { Row, Col, Tab, Nav, Container } from "react-bootstrap";

import "./Examples.css";

const EXAMPLES = [
  {
    id: "overview",
    display: "Overview",
    content: <h1>Overview</h1>,
  },
  {
    id: "prerequisites",
    display: "Prerequisites",
    content: <h1>Prerequisites</h1>,
  },
  {
    id: "authentication",
    display: "Authentication",
    content: <h1>Authentication</h1>,
  },
];

// function selectExample(key) {
//   let links = document.querySelectorAll(`[data-rb-event-key="${key}"]`);
//   _.map(links, (links) => links.click());
// }

class Examples extends React.Component {
  loadExample(key, event) {
    if (event) {
      event.preventDefault();
    }

    if (key === "overview") {
      window.location = "#";
      return;
    }

    window.location = `#${key}`;
  }

  render() {
    let navLinks = _.map(EXAMPLES, (example) => {
      return (
        <Nav.Item key={example.id}>
          <Nav.Link
            eventKey={example.id}
            onClick={(e) => this.loadExample(example.id)}
            onFocus={(e) => this.loadExample(example.id)}
          >
            {example.display}
          </Nav.Link>
        </Nav.Item>
      );
    });

    let tabPanes = _.map(EXAMPLES, (example) => {
      return (
        <Tab.Pane eventKey={example.id} key={example.id}>
          {example.content}
        </Tab.Pane>
      );
    });

    let pageContents = (
      <Tab.Container className="Examples-Container" defaultActiveKey="overview">
        <Row>
          <Col sm="2" id="Examples-Sidebar">
            <Nav variant="pills" className="flex-column">
              {navLinks}
            </Nav>
          </Col>
          <Col sm="10" md="10" lg="10" xl="7" id="Examples-MainContent">
            <Tab.Content>{tabPanes}</Tab.Content>
          </Col>
          <Col xl="3" className="d-none d-xl-block" id="Examples-RightRail">
            &nbsp;
          </Col>
        </Row>
      </Tab.Container>
    );

    return (
      <Container className="Examples-Container" fluid>
        {pageContents}
      </Container>
    );
  }
}

export default Examples;
