import React, { useState } from "react";
import _ from "lodash";
import LfQueryBuilder from "lf-query-builder";
import Credentials from "./Credentials";
import config from './config.js';
import sessionMgr from "./sessionMgr.js";
import "../node_modules/lf-query-builder/dist/esm/index.css";

function QueryBuilder(props) {
  const params = new URLSearchParams(window.location.search);

  const credentials = new Credentials(sessionMgr.getSession());

  // wrapper around Fetch API to handle auth, JSON
  const lfFetch = async (url, { authorized, ...reqOpts }) => {
    if (authorized) {
      reqOpts.headers['content-type'] = 'application/json';
      reqOpts.headers.authorization = `Bearer ${await credentials.getToken()}`;
    }
    if (!_.isString(reqOpts.body))
      reqOpts.body = JSON.stringify(reqOpts.body)
    const response = await fetch(url, { ...reqOpts });
    return await response.json();
  };

  return (
    <LfQueryBuilder
      lfFetch={lfFetch}
      accountsHost={config.accounts.url}
      apiHost={config.api_url}
      dspHost={config.dsp_url}
    />
  );
}

export default QueryBuilder;
