import React from "react";
import App from "./App";

import config from "./config.js";
import sessionMgr from "./sessionMgr.js";

function objToQueryStr(obj) {
  let kvFn = ([key, val]) => `${key}=${encodeURIComponent(val)}`;
  return Object.entries(obj)
    .map(kvFn)
    .join("&");
}

class SecureApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoggedIn: false };
  }

  oauthAuthorize() {
    let params = config.accounts.oauth_params;
    let queryStr = objToQueryStr(params);
    let url = `${config.accounts.cognito_url}/oauth2/authorize?${queryStr}`;
    window.location = url;
  }

  async handleCallback() {
    const params = new URLSearchParams(window.location.search);
    const token = await this.fetchToken(params.get("code"));
    const tokenDetails = sessionMgr.buildTokenDetails(token);
    if (token) {
      sessionMgr.updateSession(tokenDetails);
      this.setState({ isLoggedIn: true });
    }
  }

  fetchToken(code) {
    const params = new URLSearchParams("");
    params.set("grant_type", "authorization_code");
    params.set("client_id", config.accounts.oauth_params.client_id);
    params.set("redirect_uri", config.accounts.oauth_params.redirect_uri);
    params.set("code", code);
    return fetch(
      `${config.accounts.cognito_url}/oauth2/token?${params.toString()}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    ).then(response => response.json());
  }

  handleLogin() {
    if (sessionMgr.hasSession()) {
      this.setState({ isLoggedIn: true });
      sessionMgr.updateGASession(sessionMgr.getSession());
    } else {
      this.oauthAuthorize();
    }
  }

  componentDidMount() {
    if (/code=/.test(window.location)) {
      this.handleCallback();
    } else {
      this.handleLogin();
    }
  }

  render() {
    if (this.state.isLoggedIn) {
      return <App />;
    } else {
      return null;
    }

 }
}

export default SecureApp;
