import React from "react";
import { Switch, Route } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import "./App.css";
import ApiDocs from "./ApiDocs.js";
import DataDictionary from "./DataDictionary.js";
import ReleaseNotes from "./ReleaseNotes.js";
import Header from "./Header";
import Home from "./Home";
import Examples from "./Examples";
import QueryBuilder from "./QueryBuilder";

function App() {
  return (
      <div>
      <Header />
      <Container fluid className="App-Container">
        <Row>
          <Col>
            <Switch>
              <Route path="/docs">
                <ApiDocs version="v20200626" />
              </Route>
              <Route path="/dictionary">
                <DataDictionary version="v20200626" />
              </Route>
              <Route path="/releases">
                <ReleaseNotes version="v20200626" />
              </Route>
              <Route path="/query_builder">
                <QueryBuilder />
              </Route>
              <Route path="/examples">
                <Examples version="v20200626" />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Col>
        </Row>
        <Row id="App-Container-Footer">
          <Col>© 2012-{(new Date()).getFullYear()} LISTENFIRST MEDIA LLC</Col>
        </Row>
      </Container>
      </div>
  );
}

export default App;
