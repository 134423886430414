import React from 'react';

import {
  Container,
  Row,
  Col,
  Jumbotron,
  Card,
  Button,
  Image
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faBookReader, faSearch, faBook, faQuestionCircle, faPlug, faChartLine } from '@fortawesome/free-solid-svg-icons';

import './Home.css';

function FeatureCard(props) {
    return (
	<Col>
          <Card className="h-100">
              <Card.Header>
                  <Card.Title>
                      <FontAwesomeIcon icon={props.icon} />
                      {props.title}
                  </Card.Title>
              </Card.Header>
              <Card.Body>
                  <Card.Text>
                      {props.children}
                  </Card.Text>
                  <div className="card-actions">
                      <div>
                        <Button variant="primary" onClick={props.ctaFn}>{props.ctaText}</Button>
                      </div>
                      <br />
                  </div>
              </Card.Body>
          </Card>
	</Col>
    );
}

function LfFeature(props) {
    let imgHeight = props.height === undefined ? 116 : props.height;
    return (
        <Col xl="2" lg="2" md="3" sm="4" xs="6">
            <Image src={`https://assets.listenfirstmedia.com/app/vectors/start/redesign/${props.name}.svg`} height={imgHeight}/>
        </Col>
    )
}

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.contactSupport = this.contactSupport.bind(this);
        this.contactSales = this.contactSales.bind(this);
        this.openDocs = this.openDocs.bind(this);
        this.openQueryBuilder = this.openQueryBuilder.bind(this);
        this.openDict = this.openDict.bind(this);
        this.openStayInTouch = this.openStayInTouch.bind(this);
    }

    contactSupport(event) {
        event.preventDefault();
        window.location = 'mailto:apisupport@listenfirstmedia.com';
    }


    contactSales(event) {
        event.preventDefault();
        window.location = 'mailto:sales@listenfirstmedia.com';
    }

    openDocs(event) {
        event.preventDefault();
        window.location = '/docs';
    }

    openQueryBuilder(event) {
        event.preventDefault();
        window.location = '/query_builder';
    }

    openDict(event) {
        event.preventDefault();
        window.location = '/dictionary';
    }

    openStayInTouch(event) {
        event.preventDefault();
        window.location = '/docs#section/Stay-in-touch';
    }

    render() {
        return (
            <Container className="Home-Container" fluid>
                <Row>
                    <Col>
                        <Jumbotron fluid>
                            <div className="jumbotron-contents">
                                <h1>Welcome to the ListenFirst Developer's Portal</h1>
                                <p>
                                    The ListenFirst API is a set of web services providing developers
                                    with programmatic access to the vast datasets and broad analysis
                                    capabilities offered by the ListenFirst Platform.
                            </p>
                            </div>
                        </Jumbotron>
                    </Col>
                </Row>

                <Row sm={1} md={2} lg={3} xl={5} className="justify-content-sm-center" id="FeatureRow">
                    <FeatureCard icon={faKey} title="Obtain Keys" ctaFn={this.contactSupport} ctaText="Contact Support">
                        The ListenFirst API is available to Enterprise customers.
                    </FeatureCard>
                    <FeatureCard icon={faBookReader} title="Learn the APIs" ctaFn={this.openDocs} ctaText="Read Documentation">
                        Reference the documentation to learn how to make the most of the
                        ListenFirst API.
                    </FeatureCard>
                    <FeatureCard icon={faSearch} title="Query Builder" ctaFn={this.openQueryBuilder} ctaText="Construct Query">
		        Use the ListenFirst Query Builder to query data from the API to
		        use in your Business Intelligence tool.
                    </FeatureCard>
                    <FeatureCard icon={faBook} title="Explore Datasets" ctaFn={this.openDict} ctaText="Browse Dictionary">
                        Browse the ListenFirst Data Dictionary to discover the Datasets, Dimensions,
                        and Metrics available via the API.
                    </FeatureCard>
                    <FeatureCard icon={faQuestionCircle} title="Get Help" ctaFn={this.openStayInTouch} ctaText="Stay Connected">
                        We are here to help, listen, and make sure our API users are successful.
                    </FeatureCard>
                </Row>

                <Row className="justify-content-sm-center" id="Home-FeatureIconsHook">
                    <Col>
                        <div className="hook d-none d-sm-block">
                            <FontAwesomeIcon icon={faPlug} />
                            Get Started with the ListenFirst API Today!
                            <FontAwesomeIcon icon={faChartLine} />
                        </div>

                        <div className="hook hook-xs d-sm-none">
                            <FontAwesomeIcon icon={faPlug} />
                            Get Started with the API Today!
                            <FontAwesomeIcon icon={faChartLine} />
                        </div>
                    </Col>
                </Row>

                <Row className="justify-content-sm-center" id="Home-FeatureIcons">
                    <LfFeature name="brands" />
                    <LfFeature name="reporting" />
                    <LfFeature name="content" />
                    <LfFeature name="analysis" height="100"/>
                    <LfFeature name="partnerships"/>
                    <LfFeature name="video"/>
                    <LfFeature name="rankings"/>
                    <LfFeature name="paid" />
                    <LfFeature name="optimization" />
                    <LfFeature name="audience" />
                    <LfFeature name="listening" />
                    <LfFeature name="affinities" />

                </Row>
            </Container>
        );
    }
}

export default Home;
