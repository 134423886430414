import React from "react";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";

//import logo from "./LF_Logo_Color_W.png";
import logo from "./LF_Icon_Color_W.png";
import { Link } from "react-router-dom";
import "./Header.css";
import sessionMgr from "./sessionMgr.js";

class Header extends React.Component {
  render() {
    return (
      <Navbar fixed="top" expand="lg" className="Header-Navbar" variant="dark">
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="ListenFirst" height="30" />
        </Navbar.Brand>
        <Navbar.Brand
          as={Link}
          to="/"
          className="Header-PageTitle d-none d-sm-block"
        >
          ListenFirst Developer's Portal
        </Navbar.Brand>
        <Navbar.Brand
          as={Link}
          to="/"
          className="Header-PageTitle d-sm-none d-md-none d-lg-none d-xl-none"
        >
          Developer's Portal
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/docs">
              Documentation
            </Nav.Link>
            <Nav.Link as={Link} to="/dictionary">
              Data Dictionary
            </Nav.Link>
            <Nav.Link as={Link} to="/releases">
              Release Notes
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/query-builder"> comment out for now since not ready for users
              Query Builder
            </Nav.Link> */}
            {/**
            <Nav.Link as={Link} to="/examples">
              Examples
            </Nav.Link>
            **/}
            {/**
           <NavDropdown title="Documentation" id="basic-nav-dropdown">
             <NavDropdown.Item as={Link} to="/docs/current">Current Version (v2020-06-26)</NavDropdown.Item>
           </NavDropdown>
        **/}
          </Nav>
          <NavDropdown.Divider />
          <Nav className="ml-auto">
            <Nav.Link
              href="#"
              onClick={(e) => sessionMgr.handleLogout(e)}
              className="Header-SignoutLink"
            >
              Signout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
