import _ from "lodash";

const DEFAULTS = {
  lfm_env: "development",
  accounts: {
    url: "https://accounts.lfmdev.in",
    cognito_url: "https://auth.lfmdev.in",
    oauth_params: {
      redirect_uri: "http://localhost:19000/oauth_callback",
      client_id: "6ep4l754u2dglosjdqggbt2mjr",
      response_type: "code"
    }
  },
  api_url: "https://api.lfmdev.in",
  dsp_url: "https://dsp-api.lfmdev.in"
  //api_url: "http://localhost:3000"
};

const LFMDEV_OVERRIDES = {
  lfm_env: "lfmdev",
  accounts: {
    oauth_params: {
      redirect_uri: "https://developers.lfmdev.in/oauth_callback"
    }
  },
  api_url: "https://api.lfmdev.in",
  dsp_url: "https://dsp-api.lfmdev.in"
};

const LFMPROD_OVERRIDES = {
  lfm_env: "lfmprod",
  accounts: {
    url: "https://accounts.listenfirstmedia.com",
    cognito_url: "https://auth.listenfirstmedia.com",
    oauth_params: {
      client_id: "42qagai8a5irc0tueaerb17l3o",
      redirect_uri: "https://developers.listenfirstmedia.com/oauth_callback",
      response_type: "code"
    }
  },
  //api_url: "https://dsp-api.stage.lfmprod.in"
  api_url: "https://listenfirst.io",
  dsp_url: "https://dsp-api.listenfirstmedia.com"
  //api_url: "https://dsp-api.listenfirstmedia.com"
};

const LFMSTRESS_OVERRIDES = {
  lfm_env: "lfmstress",
  accounts: {
    url: "https://accounts.stress.lfmprod.in",
    cognito_url: "https://auth.stress.lfmprod.in",
    oauth_params: {
      client_id: "3evvsu8bhpaep50090qqg793hi",
      redirect_uri: "https://developers.stress.lfmprod.in/oauth_callback",
      response_type: "code"
    }
  },
  api_url: "https://stress.listenfirst.io",
  dsp_url: "https://dsp-api.stress.lfmprod.in"
};

let config = _.cloneDeep(DEFAULTS);
switch (process.env.REACT_APP_LFM_ENV) {
  case "lfmdev":
    _.merge(config, LFMDEV_OVERRIDES);
    break;
  case "lfmprod":
    _.merge(config, LFMPROD_OVERRIDES);
    break;
  case "lfmstress":
    _.merge(config, LFMSTRESS_OVERRIDES);
  default:
  //
}

export default config;
