import config from './config.js';
import moment from 'moment';
import _ from 'lodash';
const SESSION_KEY = 'oauth_token';
const API_KEY = 'api_key';
const FETCH_ARGS = 'fetch_args';
const QUERY_CREDS = 'query_creds';
const API_TOKEN = 'api_token';

let sessionMgr = {
  buildTokenDetails(token) {
    if (token.id_token) {
      let expires_in = Number(token.expires_in);
      let expires_at = moment().unix() + expires_in;

      let tokenDetails = {
        id_token: token.id_token,
        access_token: token.access_token,
        refresh_token: token.refresh_token,
        expires_at: expires_at,
        host: config.accounts.url
      };

      window.history.replaceState({}, "", "/");

      this.updateGASession(tokenDetails);

      return tokenDetails;
    } else {
      return null;
    }
  },

  async getCredentials() {
    let apiOpts = {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(window.localStorage.getItem(SESSION_KEY)).id_token}`
      }
    };

    const res = await fetch(`${config.accounts.url}/me?include_api_fields=true`, apiOpts);
    const me = await res.json();
    return {
      isApiUser: me.is_api_user,
      clientId: me.api.client_id,
      clientSecret: me.api.client_secret,
      apiKey: me.account.api_key
    };
  },

  updateGASession(tok) {
    let apiOpts = {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tok.id_token}`
      }
    };

    fetch(`${config.accounts.url}/me?include_api_fields=true`, apiOpts)
      .then(response => {
        console.log(response);
        return response.json();
      })
      .then(me => {
        console.log("firing gtag session event");
        window.localStorage.setItem(API_KEY, me.account[API_KEY]);
        window.gtag("event", "lfm-session-info", {
          "lfm-account-name": _.get(me, "account.name"),
          "lfm-customer-name": _.get(me, "customer.name"),
          "lfm-business-unit-name": _.get(me, "business_unit.name"),
          "lfm-customer-id": _.get(me, "customer.id"),
          "lfm-user-persona-name": me.user_persona,
          "lfm-account-id": _.get(me, "account.id"),
          "lfm-business-vertical-name": _.get(me, "account.vertical"),
          "lfm-business-unit-id": _.get(me, "business_unit.id"),
          "lfm-env": config.lfm_env,
          "lfm-user-id": me.id
        });
      })
      .catch(error => {
        console.log("Failed to update GA session");
        console.log(error);
      });
  },

  hasSession() {
    let token = window.localStorage.getItem(SESSION_KEY);
    if (token) {
      token = JSON.parse(token);
      if (moment.unix(token.expires_at) > Date.now()) {
        return true;
      } else {
        this.resetSession();
      }
    }
    return false;
  },

  resetSession() {
    window.localStorage.removeItem(SESSION_KEY);
    window.localStorage.removeItem(API_KEY);
    window.localStorage.removeItem(API_TOKEN);
  },

  updateSession(tokenDetails) {
    window.localStorage.setItem(SESSION_KEY, JSON.stringify(tokenDetails));
  },

  getSession() {
    let token = window.localStorage.getItem(SESSION_KEY);
    if (token) {
      token = JSON.parse(token);
      return token;
    }
    return null;
  },

  handleLogout(e) {
    e.preventDefault();
    let tok = this.getSession();
    let signoutUrl = `${
      config.accounts.cognito_url
    }/logout?response_type=code&client_id=${
      config.accounts.oauth_params.client_id
    }&redirect_uri=${config.accounts.oauth_params.redirect_uri}`;
    this.resetSession();
    window.location = signoutUrl;
  },

  setApiKey(key) {
    window.localStorage.setItem(API_KEY, key);
  },
  
  getApiKey() {
    return window.localStorage.getItem(API_KEY);
  },

  setApiToken(token) {
    window.localStorage.setItem(API_TOKEN, JSON.stringify(token));
  },

  getApiToken() {
    let token = window.localStorage.getItem(API_TOKEN);
    if (token) {
      token = JSON.parse(token);
      return token;
    }
    return null;
  },

  setFetchArgs(args) {
    window.localStorage.setItem(FETCH_ARGS, JSON.stringify(args));
  },

  getFetchArgs() {
    return window.localStorage.getItem(FETCH_ARGS);
  },

  clearFetchArgs() {
    window.localStorage.removeItem(FETCH_ARGS);
  },

  setQueryCreds(creds) {
    window.localStorage.setItem(
      QUERY_CREDS,
      JSON.stringify(_.merge(creds, {"storedApiKey": this.getApiKey()}))
    );
  },

  getQueryCreds() {
    return window.localStorage.getItem(QUERY_CREDS);
  },

  apiAuthorized() {
    const c1 = this.getApiKey();
    const c2 = this.getApiToken();
    let c3;
    // check to ensure that token is still valid
    if (c2 != null) {
	c3 = c2.expires_ms > Date.now();
    }
    return (c1 != null && c2 != null && c3);
  }
};

export default sessionMgr;
